interface Translations {
    "general.label.email": string;
    "general.label.studio.email": string;
    "general.label.email.reception": string;
    "general.label.email.invitations": string;
    "general.label.email.invitations.outside": string;
    "general.label.pec": string;
    "general.label.pec.billing": string;
    "general.label.password": string;
    "general.label.password-not-valid": string;
    "general.label.password-not-match": string;
    "general.label.phone": string;
    "general.label.mobile": string;
    "general.message.password.show": string;
    "general.message.password.hide": string;
    "general.toast.item-land-registry-success": string;
    "general.toast.invite-user-success": string;
    "general.toast.update-profile-success": string;
    "general.toast.update-profile-failure": string;
    "general.title.new-order": string;
    "general.label.all": string;
    "general.label.required": string;
    "general.label.year.range": string;
    "general.label.year.error.type": string;
    "general.label.not-negative": string;
    "general.label.max.minutes": string;
    "general.label.sdi-check": string;
    "general.label.password-length": string;
    "general.label.email-not-valid": string;
    "general.label.user-name-not-valid": string;
    "general.label.user-last-name-not-valid": string;
    "general.label.user-title-not-valid": string;
    "general.label.taxCode": string;
    "general.label.codiceSoggetto": string;
    "general.label.tax-code-not-valid": string;
    "general.label.vatNumber": string;
    "general.label.vat-number-not-valid": string;
    "general.label.vatTaxCode": string;
    "general.toast.order-created": string;
    "general.toast.order-updated": string;
    "general.label.back": string;
    "general.label.year": string;
    "general.label.istanzaCom": string;
    "general.label.name": string;
    "general.label.order-name": string;
    "general.label.lastName": string;
    "general.label.view": string;
    "general.label.role": string;
    "general.label.studio_name": string;
    "general.label.not_valid_studio_name": string;
    "general.label.address": string;
    "general.label.address.placeholder": string;
    "general.label.address.search": string;
    "general.label.street-number": string;
    "general.label.cap": string;
    "general.label.order-number": string;
    "general.label.municipality": string;
    "general.label.description": string;
    "general.label.title.email": string;
    "general.label.text.email": string;
    "general.label.relazione": string;
    "general.label.dettagli": string;
    "general.label.company-name": string;
    "general.label.search-for-operator-or-company": string;
    "general.label.upload-file": string;
    "general.label.max-upload-file": string;
    "general.label.search-for-commitment-or-company": string;
    "general.label.company": string;
    "general.label.person": string;
    "general.label.tasks": string;
    "general.label.annotations": string;
    "general.label.municipal.practices": string;
    "general.label.document": string;
    "general.label.documents": string;
    "general.label.carica-foto": string;
    "general.label.osservazioni": string;
    "general.label.osservazione": string;
    "general.label.allegati": string;
    "general.label.images": string;
    "general.label.commitmentList": string;
    "general.label.operatorList": string;
    "general.label.operatorType": string;
    "general.label.operatorName": string;
    "general.label.operatorQualityType": string;
    "general.label.operatorTypeSelect": string;
    "general.label.partecipantType": string;
    "general.label.inspections": string;
    "general.label.add.contact": string;
    "general.label.ideas": string;
    "general.label.protocols": string;
    "general.label.one-uploaded-file": string;
    "general.label.order": string;
    "general.label.team": string;
    "general.label.expiration-date": string;
    "general.label.commitments": string;
    "general.label.commitment": string;
    "general.label.operators": string;
    "general.label.operator": string;
    "general.label.acting-as": string;
    "general.label.study-management": string;
    "general.label.file-max-size": string;
    "general.label.file-squeeze": string;
    "general.label.file.not.allowed": string;
    "general.label.not.pdf.plugin": string;
    "general.label.max.pdf": string;
    "general.label.not.pdf.plugin.button": string;
    "general.label.no-uploaded-files": string;
    "general.label.no-document-list": string;
    "general.label.file": string;
    "general.label.upload-date": string;
    "general.label.size": string;
    "general.label.data": string;
    "general.label.contacts": string;
    "general.label.new-contact": string;
    "general.label.update-contact": string;
    "general.label.legal-person": string;
    "general.label.all-deadlines": string;
    "general.label.my-deadlines": string;
    "general.label.switch.year": string;
    "general.label.switch.month": string;
    "general.label.switch.day": string;
    "general.label.physical-person": string;
    "general.label.registered-office": string;
    "general.label.physical-person.address": string;
    "general.label.contact-details": string;
    "general.label.participants": string;
    "general.label.other-participants": string;
    "general.label.day": string;
    "general.label.need-admin": string;
    "general.label.multiple-admin": string;
    "general.label.argument": string;
    "general.label.titolo": string;
    "general.label.time": string;
    "general.label.duration": string;
    "general.label.filters": string;
    "general.label.in-progress": string;
    "general.label.terminated": string;
    "general.label.done": string;
    "general.label.expired": string;
    "general.label.not.expired": string;
    "general.label.expiring": string;
    "general.label.order.expired": string;
    "general.label.undefined": string;
    "general.label.settings": string;
    "general.label.summary": string;
    "general.label.territorial-data": string;
    "general.label.phases": string;
    "general.label.payments": string;
    "general.label.add-contact": string;
    "general.label.geolocation": string;
    "general.label.loading-team": string;
    "general.label.download-zip": string;
    "general.label.profile": string;
    "general.label.account": string;
    "general.label.feedback": string;
    "general.label.assistance": string;
    "general.label.missing-data": string;
    "general.label.missing-email": string;
    "general.label.missing-pec": string;
    "general.label.missing-mobile-phone": string;
    "general.label.missing-tax-code-or-vat-number": string;
    "general.label.missing-role": string;
    "general.label.missing-tax-code": string;
    "general.label.missing-vat-number": string;
    "general.label.missing-phone": string;
    "general.label.phone-number-not-valid": string;
    "general.label.missing-address": string;
    "general.label.missing-cap": string;
    "general.label.missing-email-or-pec": string;
    "general.label.close": string;
    "general.label.signatories-data": string;
    "general.label.details": string;
    "general.label.deadline": string;
    "general.label.no-results": string;
    "general.label.filters-reset": string;
    "general.label.object": string;
    "general.label.recipient": string;
    "general.label.not-defined": string;
    "general.label.idea.search-placeholder": string;
    "general.label.idea.title": string;
    "general.toast.shared.file-shared-succesfully": string;
    "general.toast.download-file-view.resend-success": string;
    "general.toast.notes.created-succesfully": string;
    "general.toast.notes.deleted-succesfully": string;
    "general.label.news": string;
    "general.label.already-present": string;
    "general.label.select-option": string;
    "general.label.land.registry-option": string;
    "general.label.select-option.task": string;
    "general.label.generic-data": string;
    "general.label.generic-user": string;
    "general.label.owner": string;
    "general.label.disabled.user": string;
    "general.label.admin": string;
    "general.label.partner.indoor": string;
    "general.label.partner.outside": string;
    "general.label.sdi": string;
    "general.label.phase-settings": string;
    "general.label.signatory-info": string;
    "general.label.signatories-info": string;
    "general.label.performance-phases": string;
    "general.label.call": string;
    "general.label.meeting": string;
    "general.label.title": string;
    "general.label.zona": string;
    "general.label.tags": string;
    "general.label.note": string;
    "general.label.cancel": string;
    "general.label.format-not-valid": string;
    "general.label.nominative": string;
    "general.label.soggetto.padre": string;
    "general.label.tipo": string;
    "general.label.contact-type": string;
    "general.label.no-contact-list": string;
    "general.label.contact-list.loading": string;
    "general.label.latest-notifications": string;
    "general.label.no-notifications": string;
    "general.label.select-map": string;
    "general.label.select-map.no-result": string;
    "general.label.select-map.no-result.reset": string;
    "general.label.select-date": string;
    "general.label.read-only-order": string;
    "general.label.order-end-cant-edit": string;
    "general.modal-title.abort-form": string;
    "general.modal-body.abort-form": string;
    "general.label.order-type": string;
    "general.label.order-status": string;
    "general.label.work-status": string;
    "general.label.order-type.tooltip": string;
    "general.label.execution.status": string;
    "general.label.execution.status.order": string;
    "general.label.website": string;
    "general.label.upload-in-progress": string;
    "general.label.upload-in-progress.document": string;
    "general.label.paperwork-import": string;
    "general.label.import-as-new-order": string;
    "general.label.import.order.number": string;
    "general.label.import.year": string;
    "general.label.import.name": string;
    "general.label.import-as-existing-order": string;
    "general.label.no-order-list": string;
    "general.label.loading": string;
    "general.label.trial-version": string;
    "general.label.button-trial-version": string;
    "general.label.subscription-expired": string;
    "general.label.subscription-expiring": string;
    "general.label.subscription-expiring.extra.1.day": string;
    "general.label.subscription-expiring.extra.2.day": string;
    "general.label.subscription-canceled": string;
    "general.label.subscription": string;
    "general.label.subscription.withdrawal.attempts.1": string;
    "general.label.subscription.withdrawal.attempts.2": string;
    "general.terms.and.privacy.text": string;
    "general.terms.and.conditions": string;
    "general.terms.and.privacy.step1": string;
    "general.terms.and.privacy.step2": string;
    "general.privacy": string;
    "general.information.privacy": string;
    "general.label.documenti-strumento-urbanistico": string;
    "general.reload.notification": string;
    "general.reload.notification.action": string;
    "general.label.enable-task-editing": string;
    "general.label.enable-task-finish-editing": string;
    "general.label.error.api": string;
}

interface General {
    it: Translations;
    en: Translations;
}

export const translationsGeneral: General = {
    it: {
        "general.label.email": "Email",
        "general.label.studio.email": "Email studio",
        "general.label.email.reception": "Email di ricezione",
        "general.label.email.invitations": "Email utente da invitare nel tuo Studio",
        "general.label.email.invitations.outside":
            "Email collaboratore esterno da invitare nel tuo Studio",
        "general.label.pec": "PEC",
        "general.label.pec.billing": "Contatti di fatturazione",
        "general.label.password": "Password",
        "general.label.password-not-valid": "Password non valida",
        "general.label.password-not-match": "Password non coincidenti",
        "general.label.phone": "Telefono",
        "general.label.mobile": "Cellulare",
        "general.message.password.show": "Mostra",
        "general.message.password.hide": "Nascondi",
        "general.toast.item-land-registry-success": "Catasto aggiornato con successo",
        "general.toast.invite-user-success": "Invito inviato con successo!",
        "general.toast.update-profile-success": "Profilo aggiornato con successo!",
        "general.toast.update-profile-failure": "Ops! impossibile aggiornare il profilo al momento",
        "general.title.new-order": "Nuova commessa",
        "general.label.all": "Tutti",
        "general.label.required": "Richiesto",
        "general.label.year.error.type": `Puoi inserire solamente dei numeri`,
        "general.label.year.range": `L'anno deve essere compresso tra 2000 e 2999`,
        "general.label.not-negative": "Il numero non deve essere negativo",
        "general.label.max.minutes": "Il numero non deve essere maggiore di 59",
        "general.label.sdi-check": "Codice SDI non valido",
        "general.label.password-length":
            "La password deve essere lunga almeno 8 caratteri, avere almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale",
        "general.label.email-not-valid": "Email non valida",
        "general.label.user-name-not-valid": "Nome non valido",
        "general.label.user-last-name-not-valid": "Cognome non valido",
        "general.label.user-title-not-valid": "Titolo non valido",
        "general.label.taxCode": "Codice fiscale",
        "general.label.codiceSoggetto": "Codice soggetto",
        "general.label.tax-code-not-valid": "Codice fiscale non valido",
        "general.label.vatNumber": "Partita IVA",
        "general.label.vat-number-not-valid": "Partita Iva non valida",
        "general.toast.order-created": "Commessa creata con successo",
        "general.toast.order-updated": "Commessa modificata con successo",
        "general.label.back": "Indietro",
        "general.label.year": "Anno",
        "general.label.istanzaCom": "Abilita creazione Istanza Comunale",
        "general.label.role": "Titolo",
        "general.label.name": "Nome",
        "general.label.order-name": "Nome commessa",
        "general.label.lastName": "Cognome",
        "general.label.studio_name": "Nome dello studio",
        "general.label.not_valid_studio_name": "Nome dello studio non valido",
        "general.label.address": "Indirizzo",
        "general.label.address.search": `Inserisci l'indirizzo, selezionalo dal menù a tendina e clicca su cerca`,
        "general.label.address.placeholder": "Es: Via crocefisso 2, Ornago 20876",
        "general.label.street-number": "Numero civico",
        "general.label.cap": "CAP",
        "general.label.order-number": "Numero Commessa",
        "general.label.municipality": "Provincia",
        "general.label.description": "Descrizione",
        "general.label.title.email": "Titolo",
        "general.label.text.email": "Messaggio",
        "general.label.relazione": "Si è accertato che",
        "general.label.dettagli": "Dettagli",
        "general.label.company-name": "Ragione sociale",
        "general.label.search-for-operator-or-company": "Cerca operatore o impresa",
        "general.label.upload-file": "Seleziona i file da risorse del sistema",
        "general.label.max-upload-file":
            "Il numero di documenti caricati, supera il limite impostato",
        "general.label.search-for-commitment-or-company": "Cerca committente o impresa",
        "general.label.company": "Impresa",
        "general.label.person": "Professionista",
        "general.label.tasks": "Attività",
        "general.label.annotations": "Annotazioni",
        "general.label.municipal.practices": "Pratica comunale",
        "general.label.document": "Documento",
        "general.label.documents": "Documenti",
        "general.label.carica-foto": "Carica foto",
        "general.label.osservazioni": "Osservazioni e note",
        "general.label.osservazione": "Osservazione",
        "general.label.allegati": "Allegati",
        "general.label.images": "Fotografie e video",
        "general.label.commitmentList": "Dati committente",
        "general.label.operatorList": "Dati operatore",
        "general.label.operatorType": "Tipo operatore",
        "general.label.operatorName": "Effettuato da",
        "general.label.operatorQualityType": "In qualità di",
        "general.label.operatorTypeSelect": "Seleziona tipo operatore",
        "general.label.partecipantType": "Tipo utente",
        "general.label.add.contact": "Aggiungi un contatto",
        "general.label.inspections": "Sopralluoghi",
        "general.label.protocols": "Protocolli",
        "general.label.view": "Visualizza",
        "general.label.one-uploaded-file": "File caricato",
        "general.label.ideas": "Bacheca idee",
        "general.label.order": "Commessa",
        "general.label.team": "Team",
        "general.label.expiration-date": "Scadenza",
        "general.label.commitments": "Committenti",
        "general.label.commitment": "Committente",
        "general.label.operators": "Operatori",
        "general.label.operator": "Operatore",
        "general.label.acting-as": "In qualità di",
        "general.label.study-management": "Gestione studio",
        "general.label.file-max-size": "Dimensione massima del file: 150MB",
        "general.label.file-squeeze": "Comprimi dimensione file (valido solo per immagini)",
        "general.label.file.not.allowed": "Anteprima non supportata",
        "general.label.not.pdf.plugin": "Il tuo browser non è in grado di aprire questo file.",
        "general.label.max.pdf":
            "Il documento è troppo grande per essere aperto in anteprima, per visualizzarlo si consiglia di cliccare sul bottone 'apertura esterna del documento' oppure eseguire il download.",
        "general.label.not.pdf.plugin.button":
            "Clicca qui per eseguire il download del file, oppure clicca sul bottone 'apertura esterna del documento' per visualizzarlo in una nuova pagina.",
        "general.label.no-uploaded-files": "Nessun file caricato",
        "general.label.no-document-list": "Nessun documento caricato",
        "general.label.file": "File",
        "general.label.upload-date": "Data upload",
        "general.label.size": "Dimensione",
        "general.label.data": "Dati",
        "general.label.contacts": "Contatti",
        "general.label.new-contact": "Nuovo contatto",
        "general.label.update-contact": "Modifica contatto",
        "general.label.legal-person": "Persona giuridica",
        "general.label.physical-person": "Persona fisica",
        "general.label.registered-office": "Sede legale",
        "general.label.physical-person.address": "Residenza",
        "general.label.contact-details": "Recapiti",
        "general.label.participants": "Presenti",
        "general.label.other-participants": "Altri soggetti",
        "general.label.all-deadlines": "Tutte le scadenze",
        "general.label.my-deadlines": "Le mie scadenze",
        "general.label.switch.year": "Fatturazione annuale",
        "general.label.switch.day": "Fatturazione giornaliera",
        "general.label.switch.month": "Fatturazione mensile",
        "general.label.day": "Giorno",
        "general.label.need-admin": "Selezionare un capo commessa",
        "general.label.multiple-admin": "Una commessa può avere solo un capo commessa",
        "general.label.argument": "Argomento",
        "general.label.titolo": "Titolo",
        "general.label.time": "Ora",
        "general.label.duration": "Durata (min)",
        "general.label.filters": "Filtri",
        "general.label.in-progress": "In Corso",
        "general.label.terminated": "Terminato",
        "general.label.done": "Completata",
        "general.label.expiring": "In ritardo",
        "general.label.expired": "In scadenza",
        "general.label.order.expired": "Scaduta",
        "general.label.not.expired": "Nei tempi",
        "general.label.undefined": "Non definito",
        "general.label.settings": "Impostazioni",
        "general.label.summary": "Riepilogo",
        "general.label.territorial-data": "Territorio",
        "general.label.performance-phases": "Fasi prestazionali",
        "general.label.payments": "Pagamenti",
        "general.label.add-contact": "Aggiungi contatto",
        "general.label.geolocation": "Geolocalizzazione",
        "general.label.loading-team": "Caricamento team in corso...",
        "general.label.download-zip": "Download zip in corso...",
        "general.label.profile": "Profilo",
        "general.label.account": "Account",
        "general.label.feedback": "Invia un Feedback",
        "general.label.assistance": "Richiedi Assistenza",
        "general.label.missing-data": "Dato mancante",
        "general.label.missing-email": "Email mancante",
        "general.label.missing-pec": "Pec mancante",
        "general.label.missing-mobile-phone": "Cellulare mancante",
        "general.label.missing-tax-code-or-vat-number": "-",
        "general.label.missing-role": "Ruolo mancante",
        "general.label.missing-tax-code": "Codice fiscale mancante",
        "general.label.missing-vat-number": "Partita IVA mancante",
        "general.label.missing-phone": "Telefono mancante",
        "general.label.phone-number-not-valid": "Numero di telefono non valido",
        "general.label.missing-address": "Indirizzo mancante",
        "general.label.missing-cap": "CAP mancante",
        "general.label.missing-email-or-pec": "Email o pec mancante",
        "general.label.close": "Chiudi",
        "general.label.signatories-data": "Dati firmatari",
        "general.label.details": "Dettagli",
        "general.label.deadline": "Scadenza",
        "general.label.no-results": "Nessun risultato trovato",
        "general.label.filters-reset": "Reset filtri",
        "general.label.enable-task-editing": "Modifica stato attività",
        "general.label.enable-task-finish-editing": "Termina modifica",
        "general.label.object": "Oggetto",
        "general.label.recipient": "Destinatario",
        "general.label.not-defined": "Non specificato",
        "general.label.idea.search-placeholder": "Cerca per titolo, descrizione o tag idea",
        "general.label.idea.title": "Bacheca idee",
        "general.toast.shared.file-shared-succesfully": "File condiviso con successo",
        "general.toast.download-file-view.resend-success": "Codice inviato corretamente per email",
        "general.toast.notes.created-succesfully": "Nota creata correttamente",
        "general.toast.notes.deleted-succesfully": "Nota eliminata correttamente",
        "general.label.news": "Nuovi",
        "general.label.already-present": "Presenti",
        "general.label.land.registry-option": "Nessuna categoria",
        "general.label.select-option": "Seleziona un'opzione",
        "general.label.select-option.task": "Nessun incaricato",
        "general.label.generic-data": "Dati generici",
        "general.label.generic-user": "Utente",
        "general.label.owner": "Titolare Studio",
        "general.label.disabled.user": "Utente rimosso",
        "general.label.admin": "Amministratore",
        "general.label.partner.indoor": "Collaboratore interno",
        "general.label.partner.outside": "Collaboratore esterno",
        "general.label.sdi": "Sdi",
        "general.label.phase-settings": "Impostazioni fase",
        "general.label.signatory-info": "Dati firmatario",
        "general.label.signatories-info": "Dati firmatari",
        "general.label.phases": "Fasi",
        "general.label.call": "Chiamata",
        "general.label.meeting": "Riunione",
        "general.label.title": "Titolo",
        "general.label.zona": "Zona",
        "general.label.tags": "Tags",
        "general.label.note": "Notes",
        "general.label.cancel": "Annulla",
        "general.label.vatTaxCode": "CF / P.IVA",
        "general.label.format-not-valid": "Formato non valido",
        "general.label.nominative": "Nominativo",
        "general.label.soggetto.padre": "Contatto principale",
        "general.label.tipo": "Tipo contatto",
        "general.label.contact-type": "Tipo contatto",
        "general.label.no-contact-list": "Nessun contatto presente!",
        "general.label.contact-list.loading": "Rubrica in caricamento",
        "general.label.latest-notifications": "Ultime notifiche",
        "general.label.no-notifications": "Nessuna notifica presente",
        "general.label.select-map": "Indica in mappa il luogo oggetto dell’intervento",
        "general.label.select-map.no-result": "Nessuna commessa trovata",
        "general.label.select-map.no-result.reset":
            "resetta i filtri per effettuare una nuova ricerca.",
        "general.label.select-date": "Seleziona data",
        "general.label.read-only-order": "Commessa in sola lettura",
        "general.label.order-end-cant-edit": "Commessa chiusa, non modificabile",
        "general.modal-title.abort-form": "Uscire senza salvare",
        "general.modal-body.abort-form":
            "Sembra che tu non abbia salvato, sei sicuro di voler continuare?",
        "general.label.order-type": "Tipo commessa",
        "general.label.order-status": "Stato commessa",
        "general.label.work-status": "Stato fase",
        "general.label.order-type.tooltip": "Per aggiungere ulteriori voci, vai alla sezione",
        "general.label.execution.status": "Stato esecuzione",
        "general.label.execution.status.order": "Stato esecuzione commessa",
        "general.label.website": "Sito internet",
        "general.label.upload-in-progress": "Upload in corso...",
        "general.label.upload-in-progress.document": "Recupero documenti...",
        "general.label.paperwork-import": "Importa pratica",
        "general.label.import-as-new-order": "Importa come nuova commessa",
        "general.label.import-as-existing-order": "Importa in una commessa esistente",
        "general.label.import.order.number": "Numero commessa",
        "general.label.import.year": "Anno",
        "general.label.import.name": "Nome commessa",
        "general.label.no-order-list": "Nessuna commessa presente",
        "general.label.loading": "Caricamento in corso...",
        "general.label.trial-version": "Il tuo abbonamento di prova scade tra",
        "general.label.button-trial-version": "Attiva abbonamento",
        "general.label.subscription-expired": "Il tuo abbonamento è scaduto",
        "general.label.subscription-expiring": "Il tuo abbonamento scadrà tra",
        "general.label.subscription-expiring.extra.2.day":
            "Il tuo abbonamento è appena scaduto, disponi ancora di 2 giorno extra per poterlo rinnovare prima che venga cancellato.",
        "general.label.subscription-expiring.extra.1.day":
            "Il tuo abbonamento è appena scaduto, disponi ancora di 1 giorno extra per poterlo rinnovare prima che venga cancellato.",
        "general.label.subscription-canceled": "Abbonamento annullato in data",
        "general.label.subscription": "Vai al tuo abbonamento",
        "general.label.subscription.withdrawal.attempts.1": "Ti restano ancora",
        "general.label.subscription.withdrawal.attempts.2":
            "prima che il tuo abbonamento venga disattivato. Per favore controlla che i dati della tua carta di credito siano corretti.",
        "general.terms.and.privacy.text": "Ho letto e accettato",
        "general.terms.and.conditions": "I Termini e Condizioni di utilizzo",
        "general.terms.and.privacy.step1": "Ho letto e accetto i termini di cui sopra.",
        "general.terms.and.privacy.step2": `Ai sensi e per gli effetti degli articoli 1341 e 1342 c.c. dichiaro di aver bene
    compreso e di accettare il contenuto dei seguenti articoli: 3.5 (Limitazione
    responsabilità per utilizzo Credenziali); 4.6 (Rinnovo automatico abbonamento);
    4.7 (Modifica alle condizioni di abbonamento); 7 (Livelli di servizio); 10
    (Aggiornamenti e Sviluppi); 11 (Proprietà intellettuale); 14 (Divieti); 15
    (Clausola risolutiva espressa); 16 (Limitazioni di responsabilità per l'utilizzo
    della Piattaforma); 17 (Riservatezza); 19.2 (Foro competente); 20.2 (Cessione
    del Contratto).`,
        "general.privacy": `l'informativa sulla privacy`,
        "general.information.privacy": "Starch s.r.l. informativa privacy",
        "general.label.documenti-strumento-urbanistico": "Documenti Strumento Urbanistico",
        "general.reload.notification": "Ci sono delle nuove notifiche per te",
        "general.reload.notification.action": "Clicca per visualizzarle",
        "general.label.error.api":
            "Servizio momentaneamente non disponibile, stiamo lavorando per risolvere il problema.",
    },
    en: {
        "general.label.email": "Indirizzo email",
        "general.label.studio.email": "Email studio",
        "general.label.email.reception": "Email di ricezione",
        "general.label.email.invitations": "Email utente da invitare nel tuo Studio",
        "general.label.email.invitations.outside":
            "Email collaboratore esterno da invitare nel tuo Studio",
        "general.label.pec": "PEC",
        "general.label.pec.billing": "Contatti di fatturazione",
        "general.label.password": "Password",
        "general.label.password-not-valid": "Password non valida",
        "general.label.password-not-match": "Password non coincidenti",
        "general.label.phone": "Phone",
        "general.label.mobile": "Mobile",
        "general.message.password.show": "SHOW",
        "general.message.password.hide": "HIDE",
        "general.toast.item-land-registry-success": "Catasto aggiornato con successo",
        "general.toast.invite-user-success": "Invitation sent successfully",
        "general.toast.update-profile-success": "Update profile succesfuly!",
        "general.toast.update-profile-failure": "Ops! unable to update profile at this time",
        "general.title.new-order": "New Order",
        "general.label.sdi-check": "Codice SDI non valido",
        "general.label.all": "All",
        "general.label.user-name-not-valid": "Name not valid",
        "general.label.user-last-name-not-valid": "Last name not valid",
        "general.label.user-title-not-valid": "Title not valid",
        "general.label.required": "Required",
        "general.label.year.error.type": `Puoi inserire solamente dei numeri`,
        "general.label.year.range": `L'anno deve essere compresso tra 2000 e 2999`,
        "general.label.not-negative": "The number cannot be negative",
        "general.label.max.minutes": "Il numero non deve essere maggiore di 59",
        "general.label.password-length": "La password deve avere almeno 8 caratteri",
        "general.label.email-not-valid": "Not valid email",
        "general.label.taxCode": "Tax Code",
        "general.label.codiceSoggetto": "Codice soggetto",
        "general.label.tax-code-not-valid": "Codice fiscale non valido",
        "general.label.vatNumber": "VAT Number",
        "general.label.vat-number-not-valid": "Vat Number not valid",
        "general.toast.order-created": "Order created succesfully",
        "general.toast.order-updated": "Order updated succesfully",
        "general.label.back": "Back",
        "general.label.year": "Year",
        "general.label.istanzaCom": "Abilita creazione Istanza Comunale",
        "general.label.name": "Name",
        "general.label.order-name": "Order name",
        "general.label.lastName": "Last Name",
        "general.label.role": "Role",
        "general.label.studio_name": "Studio name",
        "general.label.not_valid_studio_name": "Studio name not valid",
        "general.label.address": "Address",
        "general.label.address.search": `Cerca l'indirizzo della tua commessa`,
        "general.label.address.placeholder": "Es: Via crocefisso 2, Ornago 20876",
        "general.label.cap": "CAP",
        "general.label.order-number": "Order Number",
        "general.label.municipality": "Municipality",
        "general.label.street-number": "Number",
        "general.label.description": "Description",
        "general.label.title.email": "Titolo",
        "general.label.text.email": "Messaggio",
        "general.label.relazione": "Relazione sopralluogo",
        "general.label.dettagli": "Dettagli sopralluogo",
        "general.label.company-name": "Company name",
        "general.label.search-for-operator-or-company": "Search for operator or company",
        "general.label.upload-file": "Select files from system resources",
        "general.label.max-upload-file":
            "Il numero di documenti caricati, supera il limite impostato",
        "general.label.search-for-commitment-or-company": "Search for commitment or company",
        "general.label.company": "Company",
        "general.label.person": "Professional",
        "general.label.tasks": "Tasks",
        "general.label.annotations": "Annotations",
        "general.label.municipal.practices": "Pratica comunale",
        "general.label.document": "Document",
        "general.label.documents": "Documents",
        "general.label.carica-foto": "Upload photo",
        "general.label.osservazioni": "Osservazioni e note",
        "general.label.osservazione": "Osservazione",
        "general.label.allegati": "Attachments",
        "general.label.images": "Images",
        "general.label.commitmentList": "Dati committente",
        "general.label.operatorList": "Dati operatore",
        "general.label.operatorType": "Tipo operatore",
        "general.label.operatorName": "Effettuato da",
        "general.label.operatorQualityType": "In qualità di",
        "general.label.operatorTypeSelect": "Seleziona tipo operatore",
        "general.label.partecipantType": "Tipo utente",
        "general.label.add.contact": "Aggiungi un contatto",
        "general.label.inspections": "Inspections",
        "general.label.protocols": "Protocolli",
        "general.label.view": "View",
        "general.label.one-uploaded-file": "Uploaded file",
        "general.label.ideas": "Bacheca idee",
        "general.label.order": "Order",
        "general.label.team": "Team",
        "general.label.expiration-date": "Expiration date",
        "general.label.commitments": "Customers",
        "general.label.commitment": "Customer",
        "general.label.operators": "Operators",
        "general.label.operator": "Operator",
        "general.label.acting-as": "As",
        "general.label.study-management": "Study management",
        "general.label.file-max-size": "Maximum file size: 50MB",
        "general.label.file-squeeze": "Comprimi dimensione file (valido solo per immagini)",
        "general.label.file.not.allowed": "Anteprima non supportata",
        "general.label.not.pdf.plugin": `Your web browser doesn't have a PDF plugin.`,
        "general.label.max.pdf":
            "Il documento è troppo grande per essere aperto in anteprima, per visualizzarlo si consiglia di cliccare sul bottone 'apertura esterna del documento' oppure eseguire il download.",
        "general.label.not.pdf.plugin.button":
            "Instead you can click here to download the PDF file.",
        "general.label.no-uploaded-files": "No files have been uploaded",
        "general.label.no-document-list": "No documents have been uploaded",
        "general.label.file": "File",
        "general.label.upload-date": "Upload date",
        "general.label.size": "Size",
        "general.label.data": "Data",
        "general.label.contacts": "Contacts",
        "general.label.new-contact": "New contact",
        "general.label.update-contact": "Edit contact",
        "general.label.legal-person": "Legal person",
        "general.label.physical-person": "Physical person",
        "general.label.registered-office": "Registered office",
        "general.label.physical-person.address": "Residenza",
        "general.label.contact-details": "Contact details",
        "general.label.participants": "Participants",
        "general.label.other-participants": "Other partecipants",
        "general.label.day": "Day",
        "general.label.need-admin": "Add order manager",
        "general.label.multiple-admin": "An order has only one order manager",
        "general.label.argument": "Argument",
        "general.label.titolo": "Title",
        "general.label.time": "Time",
        "general.label.duration": "Duration (min)",
        "general.label.filters": "Filters",
        "general.label.in-progress": "In Progress",
        "general.label.terminated": "Concluded",
        "general.label.done": "Completata",
        "general.label.expired": "Expired",
        "general.label.not.expired": "Nei tempi",
        "general.label.expiring": "Expiring",
        "general.label.order.expired": "Scaduta",
        "general.label.undefined": "Non definito",
        "general.label.settings": "Settings",
        "general.label.summary": "Summary",
        "general.label.territorial-data": "Territorial data",
        "general.label.phases": "Phases",
        "general.label.payments": "Payments",
        "general.label.add-contact": "Add contact",
        "general.label.geolocation": "Geolocation",
        "general.label.loading-team": "Loading team...",
        "general.label.download-zip": "Download zip in corso...",
        "general.label.profile": "Profile",
        "general.label.account": "Account",
        "general.label.feedback": "Invia un Feedback",
        "general.label.assistance": "Richiedi Assistenza",
        "general.label.missing-data": "Missing data",
        "general.label.missing-email": "Missing email",
        "general.label.missing-pec": "Missing pec",
        "general.label.missing-mobile-phone": "Missing mobile phone number",
        "general.label.missing-tax-code-or-vat-number": "Missing Tax code or VAT number",
        "general.label.missing-role": "Missing role",
        "general.label.missing-tax-code": "Missing Tax code",
        "general.label.missing-vat-number": "Missing VAT number",
        "general.label.missing-phone": "Missing phone number",
        "general.label.phone-number-not-valid": "Phone number not valid",
        "general.label.missing-address": "Missing address",
        "general.label.missing-cap": "Missing CAP",
        "general.label.missing-email-or-pec": "Missing email or pec",
        "general.label.close": "Close",
        "general.label.signatories-data": "Signatories data",
        "general.label.details": "Details",
        "general.label.deadline": "Deadline",
        "general.label.no-results": "No results found",
        "general.label.filters-reset": "Reset filters",
        "general.label.enable-task-editing": "Modifica stato attività",
        "general.label.enable-task-finish-editing": "Termina modifica",
        "general.label.object": "Title",
        "general.label.recipient": "Recipient",
        "general.label.not-defined": "Not defined",
        "general.label.idea.search-placeholder": "Search for title, description or tag idea",
        "general.label.idea.title": "Bacheca idee",
        "general.toast.shared.file-shared-succesfully": "File shared succesfully",
        "general.toast.download-file-view.resend-success": "Code succesfully sent by email",
        "general.toast.notes.created-succesfully": "Notes added succesfully",
        "general.toast.notes.deleted-succesfully": "Notes deleted succesfully",
        "general.label.news": "New",
        "general.label.already-present": "Already present",
        "general.label.select-option": "Select an option",
        "general.label.land.registry-option": "Nessuna categoria",
        "general.label.select-option.task": "Nessun incaricato",
        "general.label.generic-data": "Generic data",
        "general.label.generic-user": "User",
        "general.label.owner": "Titolare Studio",
        "general.label.disabled.user": "Utente rimosso",
        "general.label.admin": "Admin",
        "general.label.partner.indoor": "Collaboratore interno",
        "general.label.partner.outside": "Collaboratore esterno",
        "general.label.sdi": "Sdi",
        "general.label.phase-settings": "Phase settings",
        "general.label.signatory-info": "Signatory info",
        "general.label.signatories-info": "Signatories info",
        "general.label.performance-phases": "Performance phases",
        "general.label.call": "Call",
        "general.label.meeting": "Meeting",
        "general.label.title": "Title",
        "general.label.zona": "Zone",
        "general.label.tags": "Tags",
        "general.label.note": "Notes",
        "general.label.cancel": "Cancel",
        "general.label.vatTaxCode": "Tax code / Vat number",
        "general.label.format-not-valid": "Format not valid",
        "general.label.nominative": "Nominative",
        "general.label.soggetto.padre": "Contatto principale",
        "general.label.tipo": "Tipo contatto",
        "general.label.contact-type": "Contact type",
        "general.label.no-contact-list": "No contacts!",
        "general.label.contact-list.loading": "Rubrica in caricamento",
        "general.label.latest-notifications": "Latest notifications",
        "general.label.no-notifications": "No notifications",
        "general.label.select-map": "Indicates the place of the intervention on the map",
        "general.label.select-map.no-result": "Nessuna commessa trovata",
        "general.label.select-map.no-result.reset":
            "resetta i filtri per effettuare una nuova ricerca.",
        "general.label.select-date": "Select date",
        "general.label.read-only-order": "Read-only order",
        "general.label.order-end-cant-edit": "Closed order, not editable",
        "general.label.all-deadlines": "Tutte le scadenze",
        "general.label.my-deadlines": "Le mie scadenze",
        "general.label.switch.year": "Fatturazione annuale",
        "general.label.switch.day": "Fatturazione giornaliera",
        "general.label.switch.month": "Fatturazione mensile",
        "general.modal-title.abort-form": "Exit without saving",
        "general.modal-body.abort-form":
            "It seems you haven't saved, are you sure you want to continue?",
        "general.label.order-type": "Order type",
        "general.label.order-status": "Stato commessa",
        "general.label.work-status": "Stato fase",
        "general.label.order-type.tooltip": "Per aggiungere ulteriori voci, vai alla sezione",
        "general.label.execution.status": "Stato esecuzione",
        "general.label.execution.status.order": "Stato esecuzione commessa",
        "general.label.website": "Website",
        "general.label.upload-in-progress": "Upload in progress...",
        "general.label.upload-in-progress.document": "Recupero documenti...",
        "general.label.paperwork-import": "Import paperwork",
        "general.label.import-as-new-order": "Import as new order",
        "general.label.import.order.number": "Numero commessa",
        "general.label.import.year": "Anno",
        "general.label.import.name": "Nome commessa",
        "general.label.import-as-existing-order": "Import as existing order",
        "general.label.no-order-list": "No orders",
        "general.label.loading": "Loading...",
        "general.label.trial-version": "Trial version",
        "general.label.button-trial-version": "Attiva abbonamento",
        "general.label.subscription-expired": "Subscription expired",
        "general.label.subscription-expiring": "Your subscription will expire in",
        "general.label.subscription-expiring.extra.2.day":
            "Il tuo abbonamento è appena scaduto, disponi ancora di 2 giorno extra per poterlo rinnovare prima che venga cancellato.",
        "general.label.subscription-expiring.extra.1.day":
            "Il tuo abbonamento è appena scaduto, disponi ancora di 1 giorno extra per poterlo rinnovare prima che venga cancellato.",
        "general.label.subscription-canceled": "Abbonamento canceled on",
        "general.label.subscription": "Vai al tuo abbonamento",
        "general.label.subscription.withdrawal.attempts.1": "Ti restano ancora",
        "general.label.subscription.withdrawal.attempts.2":
            "prima che il tuo abbonamento venga disattivato. Per favore controlla che i dati della tua carta di credito siano corretti.",
        "general.terms.and.privacy.text": "Ho letto e accettato",
        "general.terms.and.conditions": "I Termini e Condizioni di utilizzo",
        "general.terms.and.privacy.step1": "Ho letto e accetto i termini di cui sopra.",
        "general.terms.and.privacy.step2": `Ai sensi e per gli effetti degli articoli 1341 e 1342 c.c. dichiaro di aver bene
    compreso e di accettare il contenuto dei seguenti articoli: 3.5 (Limitazione
    responsabilità per utilizzo Credenziali); 4.6 (Rinnovo automatico abbonamento);
    4.7 (Modifica alle condizioni di abbonamento); 7 (Livelli di servizio); 10
    (Aggiornamenti e Sviluppi); 11 (Proprietà intellettuale); 14 (Divieti); 15
    (Clausola risolutiva espressa); 16 (Limitazioni di responsabilità per l'utilizzo
    della Piattaforma); 17 (Riservatezza); 19.2 (Foro competente); 20.2 (Cessione
    del Contratto).`,
        "general.privacy": `l'informativa sulla privacy`,
        "general.information.privacy": "Starch s.r.l. informativa privacy",
        "general.label.documenti-strumento-urbanistico": "Documenti Strumento Urbanistico",
        "general.reload.notification": "Ci sono delle nuove notifiche per te",
        "general.reload.notification.action": "Clicca per visualizzarle",
        "general.label.error.api":
            "Servizio momentaneamente non disponibile, stiamo lavorando per risolvere il problema.",
    },
};

export type TTranslationsGeneral = keyof Translations;
