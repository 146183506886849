import { RiepilogoCommessa } from "@magoot-sdk/api/order/riepilogo/response";

import { TStore } from "../reducers/declarations";
import { TOrder } from "../reducers/reducerOrder/declarations";

export const selectorOrderSelected = ({
    store,
    idOrder,
}: {
    store: TStore;
    idOrder: number;
}): RiepilogoCommessa => {
    const orders = store.orders || {};
    return orders[idOrder];
};

export const selectorOrderList = ({ orders }: TStore): TOrder[] => {
    const ordersList = orders ? Object.keys(orders).map((key) => orders[key]) : undefined;

    return ordersList ?? [];
};
